.inputLabel {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--black-800);
  margin-top: -1px;

  .required {
    color: var(--pink-500);
  }

  .tooltip {
    display: flex;
    align-items: center;
    color: var(--grey-500);
  }

  .nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.contrasting {
  font-size: 14px;
  font-weight: 500;
  color: var(--black-900);
  margin-top: -2px;
}

.tooltipInput {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
