:root {
  --white: #ffffff;

  --black-900: #1a1a1a;
  --black-800: #3b3c40;

  --grey-100: #f7f8fa;
  --grey-300: #e1e3e5;
  --grey-400: #c4c7cc;
  --grey-500: #95989d;
  --grey-800: #3b3c40;
  --grey-900: #212121;

  --green-500: #11A65B;

  --blue-500-16: rgba(0, 119, 204, 0.16);
  --blue-500-08: rgba(0, 119, 204, 0.08);
  --blue-500: #0077cc;
  --blue-600: #006bb8;
  --blue-700: #005fa3;

  --pink-500-08: rgba(242, 76, 73, 0.08);
  --pink-500: #e62e6b;
  --pink-600: #db215f;
  --pink-700: #cc1f59;

  --text-main-color: var(--black-900);
  --text-secondary-color: var(--grey-500);

  --gradient: linear-gradient(90deg, #941e94 0%, #e52e6b 100%);

  --box-shadow: 0 2px 8px rgba(8, 35, 48, 0.04), 0 1px 4px rgba(8, 35, 48, 0.16);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-main-color);
  -webkit-box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset;
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset;
  transition: background-color 5000s ease-in-out 0s;
}
