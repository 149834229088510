.linkModal {
  .linkModalRow {
    margin-top: 16px;

    .inputIcon {
      color: var(--grey-500);
      cursor: pointer;
    }

    &:last-child {
      margin-top: 32px;
      margin-bottom: 8px;
      display: flex;

      .redirectButton {
        display: flex;
        align-items: center;
        color: var(--grey-500);
        margin-left: 8px;
        padding: 0 7px;
        border-radius: 2px;
        border: 1px solid var(--grey-300);

        &:hover {
          color: var(--blue-500);
        }
      }

      .disabledRedirectButton {
        pointer-events: none;
      }
    }
  }
}
