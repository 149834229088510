.toolbar {
  display: flex;
  padding: 16px 0 8px;
  border-bottom: solid 1px var(--grey-300);

  .buttonsGroup {
    display: flex;
    padding: 0 24px;
    border-right: 1px solid var(--grey-300);
    border-left: 1px solid var(--grey-300);
    align-items: center;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    .toolbarButton {
      border: none;
      padding: 0;
      min-width: 0;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: var(--blue-500);
        background-color: var(--blue-500-08);
      }
    }

    .activeToolbarButton {
      color: var(--blue-500);
      background-color: var(--blue-500-08);
    }
  }

  .colorPickerTriggerWrapper {
    width: 24px;
    height: 24px;
    padding: 4px;
    min-width: 0;

    .colorPickerTrigger {
      width: 16px;
      height: 16px;
      border-radius: 2px;
    }

    &:hover {
      background-color: var(--blue-500-08);

      .colorPickerTrigger {
        outline: 1px solid var(--blue-500);
      }
    }
  }

  .specialCharactersTrigger {
    width: 24px;
    height: 24px;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:hover {
      background-color: var(--blue-500-08);
      color: var(--blue-500);
    }
  }


  .linkButton {
    width: 24px;
    height: 24px;
    padding: 0;
    min-width: 0;

    &:hover {
      background-color: var(--blue-500-08);
      color: var(--blue-500);
    }
  }
}

.colorPopover {
  padding: 8px;
  margin-top: 9px;
  width: 104px;
  display: flex;

  .colorButton {
    padding: 0;
    min-width: 0;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  .colorIcon {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      outline: 1px solid var(--blue-500);
    }
  }
}

.specialCharactersPopover {
  margin-top: 9px;
  margin-left: -9px;

  .specialCharactersList {
    width: 288px;
    padding: 8px 0;

    li {
      padding: 6px 24px 6px 36px;
    }
  }
}
