.linkComponent {
  cursor: pointer;
}

.warningBanner {
  padding: 8px 12px;
  margin: 8px 0;
  border-left: 3px solid;

  h3 {
    padding: 4px 0;
  }

  &.warningBanner1 {
    background-color: var(--blue-500-08);
    border-color: var(--blue-500);
  }

  &.warningBanner2 {
    background-color: var(--grey-300);
    border-color: var(--grey-500);
  }
}
