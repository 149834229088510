.fullScreenDialog {
  position: relative;

  .closeButton {
    position: absolute;
    right: 32px;
    top: 24px;
    color: var(--grey-500);
    width: 40px;
    height: 40px;
    border: 1px solid var(--grey-300);
    border-radius: 50%;
  }
}
