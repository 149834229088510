.wrapper {
  .editor {
    border: solid 1px var(--gray-300, #E1E3E5);
    border-radius: 2px;
    position: relative;

    &.hasError {
      border-color: var(--pink-600);
    }

    &.disabled {
      background-color: var(--grey-100);

      .editable {
        resize: none;
        overflow-y: hidden;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--grey-100);
        opacity: 0.7;
      }
    }
  }

  .title {
    position: absolute;
    transform: translate(11px, -7px);
    padding: 0 2px;
    z-index: 1;
    display: block;
    background: white;
  }

  .errorMessage {
    font-size: 0.75rem;
    color: var(--pink-600);
    margin: 3px 12px 0;
  }

  .editable {
    margin: 12px;
    padding-right: 16px;
    font-size: 14px;
    height: 85px;
    min-height: 85px;
    resize: vertical;
    overflow-y: auto;
    overscroll-behavior-y: none;

    &:focus {
      outline: none;
    }

    ol {
      margin-left: 32px;
      list-style: auto;
    }

    ul {
      margin-left: 32px;
      list-style: disc;
    }

    a {
      text-decoration: none;
      color: var(--blue-500);
    }
  }
}
