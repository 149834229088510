* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  color: var(--text-main-color);
  min-width: 320px;
  min-height: 100vh;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ol,
ul {
  list-style: none;
}

.gradient {
  background-image: var(--gradient);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

#root > .page {
  flex: 1;
}

.pageError {
  padding: 16px 16px 32px;
  width: 100%;
  background-color: white;
  text-align: center;
  position: relative;
}

.pageError__content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  padding: 0 16px;
}

@media screen and (max-height: 500px) {
  .pageError__content {
    position: static;
    top: unset;
    transform: unset;
  }
}

.imageSection {
  margin: 0 auto 16px;
  max-width: 600px;
  height: 400px;
}

.loaderScreen {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}
