.page {
  padding: 16px;
  display: flex;
}

.page-login {
  background-color: var(--grey-100);
}

.pageContent {
  width: 100%;
  position: relative;
  background-color: white;
  box-shadow: var(--box-shadow);
}

.formWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 16px 16px 32px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-height: 500px) {
  .formWrapper {
    position: static;
    top: unset;
    transform: unset;
  }
}

.loginForm {
  width: 100%;
  max-width: 360px;
  text-align: center;
}
